import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Grid, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CDProcessingStyles } from './CDProcessingStyles';
import ThankyouTick from '../../../images/group_769.png';
import TwoCoin from '../../../images/two_coins.svg';
import download from '../../../images/download.png';
import { getConsentTermsAndConditions } from '../../../services/consentService';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import Loader from '../../Loader/Loader';
import DCDLinkExpiryModal from '../../Modal/DCDLinkExpiryModal';
import { productNameURLMapping } from '../../../services/productNameURLMapping';
import CDProcessingLoader from './CDProcessingLoader';
import { isCDproduct } from '../../commonCode';
import KnockOffModal from '../StepTwo/KnockOffModal';

const EmailReviewCD = (props) => {
  const [agreeAndSubmit, setAgreeAndSubmit] = useState(false);
  const { t } = useTranslation();
  const classes = CDProcessingStyles();
  const theme = useTheme();

  const { history } = props;

  const isMobile600 = useMediaQuery(theme?.breakpoints?.down('600'), {
    defaultMatches: true,
  });
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [termsAndConditionsResp, setTermsAndConditionsResp] = useState('');
  const leadGenerationContext = useContext(LeadGenerationContext);

  const { accessToken, applicationId, setAutomatedAccDetails } = useContext(LeadGenerationContext);
  const [loading, setLoading] = useState(false);
  const [expiryModal, setExpiryModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [apiCallCount, setApiCallCount] = useState(1);
  const [apiCounterEnabled, setApiCounterEnabled] = useState(false);

  const [openAccountLoader, setOpenAccountLoader] = useState(false);

  // const [apiCounterEnabled, setApiCounterEnabled] = useState(false);

  useEffect(async () => {
    if (leadGenerationContext.accessToken && leadGenerationContext.applicationId) {
      setLoading(true);
      try {
        const response = await getConsentTermsAndConditions(
          leadGenerationContext.applicationId,
          leadGenerationContext.accessToken
        );
        // console.log('response', response);
        if (
          response?.data?.cdAutomatedFlow === 'ExternalFlow' ||
          response?.data?.cdAutomatedFlow === 'ReviewFlow'
        ) {
          setTermsAndConditions(response?.data?.presignedUrl);
          setLoading(false);
        }
        setTermsAndConditionsResp(response);
        if (response?.status === 400) {
          console.log({ response });
          setExpiryModal(true);
          setModalMessage(response?.data);
          setLoading(false);
        } else {
          setExpiryModal(false);
          setModalMessage('');
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        if (error?.status === 400) {
          console.log({ error });
          setExpiryModal(true);
          setModalMessage(error?.response?.data);
        } else {
          setExpiryModal(false);
          setModalMessage('');
        }
      } finally {
        setLoading(false);
      }
    }
  }, [applicationId, accessToken]);

  useEffect(() => {
    const secInterval = setInterval(() => {
      if (apiCallCount < 23 && apiCounterEnabled) {
        callApi(apiCallCount);
      } else {
        setLoading(false);
        clearInterval(secInterval);
      }
    }, 4000);
    return () => clearInterval(secInterval);
  }, [apiCallCount]);

  const callApi = async (count) => {
    let response = null;
    try {
      response = await axios.post(
        process.env.REACT_APP_AUTOMATED_FLOW_URL,
        {
          applicationId,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.status === 200) {
        leadGenerationContext.setTermsAndConditionPreSignedURL(
          response.data.accountDetails?.presignedUrl
        );
      }
    } catch (e) {
      // return response;
      if (e?.status === 400) {
        console.log({ e });
        setExpiryModal(true);
        setModalMessage(e?.response?.data);
      } else {
        setExpiryModal(false);
        setModalMessage('');
      }
      setLoading(false);
    }

    switch (response?.data?.processingStatus) {
      case 'Inprogress': {
        setLoading(true);
        break;
      }
      // case 'ReviewFlow':
      case 'AccountCreated':
      case 'AutomatedFlow': {
        setLoading(false);
        setOpenAccountLoader(true);
        if (response?.data?.accountDetails?.accountCreated === true) {
          setLoading(false);
          setApiCounterEnabled(false);
          setOpenAccountLoader(false);
          setAutomatedAccDetails(response?.data?.accountDetails);
          leadGenerationContext.setTermsAndConditionPreSignedURL(
            response?.data?.accountDetails?.presignedUrl
          );
          // setFlowTypeETB('');

          history.push({
            pathname: `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/dcd/certificate-of-deposit/thankyou-automated`,
            applicationId: leadGenerationContext.applicationId,
          });
        } else if (count === 22 && response?.data?.accountDetails?.accountCreated === false) {
          setLoading(false);
          setApiCounterEnabled(false);
          setOpenAccountLoader(false);
          history.push({
            pathname: `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/dcd/certificate-of-deposit/thankyou-CD`,
            applicationId: leadGenerationContext.applicationId,
          });
        } else {
          setApiCallCount((prevState) => prevState + 1);
        }
        break;
      }
      case 'Error':
        setLoading(false);
        setApiCounterEnabled(false);
        setOpenAccountLoader(false);
        history.push({
          pathname: `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/dcd/certificate-of-deposit/thankyou-CD`,
          applicationId: leadGenerationContext.applicationId,
        });

        break;

      default:
        break;
    }
    return response;
  };

  // let count = 1;
  // const checkAutomatedFlowStatus = async () => {
  //   const processStatus = ['ReviewFlow', 'AccountCreated', 'AutomatedFlow'];
  //   try {
  //     const response = await callApi();

  //     if (
  //       processStatus?.includes(response?.data?.processingStatus) &&
  //       response?.data?.accountDetails?.accountNumber?.[0]
  //     ) {
  //       setLoading(false);
  //       count = 23;

  //       history?.push(
  //         `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/thankyou-automated-cd`
  //       );
  //       setLoading(false);
  //     } else if (count < 23) {
  //       count += 1;
  //       setTimeout(checkAutomatedFlowStatus, 4000);
  //     }
  //     // else if (response?.status === 200 && !response?.data?.accountDetails?.accountCreated) {
  //     //   history?.push(
  //     //     `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/thankyou-email-CD`
  //     //   );
  //     //   setLoading(false);
  //     // }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     if (count < 22) {
  //       count += 1;
  //       setTimeout(checkAutomatedFlowStatus, 4000);
  //     } else {
  //       setLoading(false);
  //       console.error('Max retries reached');
  //     }
  //   }
  // };

  const handleSave = async () => {
    const formData = {
      Event: 'ChildCaseApprove',
      Application: {
        DCDUIFlow: true,
        ApplicationID: leadGenerationContext.applicationId,

        Processing: {
          CaseReference: [
            {
              CaseType: 'UIDCDApproved',
              CaseStatus: 'Approved',
            },
          ],
        },
      },
    };
    let result;
    try {
      result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
        headers: {
          Authorization: `Bearer ${leadGenerationContext.accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      console.log({ result });
    } catch (error) {
      if (error?.status === 400) {
        console.log({ error });
        setExpiryModal(true);
        setModalMessage(error?.response?.data);
      } else {
        setExpiryModal(false);
        setModalMessage('');
      }
      setLoading(false);
    }

    return result;
  };

  const handleContinue = async () => {
    setLoading(true);
    const result = await handleSave();
    if (result && result?.status === 201) {
      setApiCounterEnabled(true);
      await callApi(result);
    }
  };

  return (
    <div className={classes.mainContainer}>
      {termsAndConditions && !openAccountLoader && (
        <Grid container className={classes.thankyouGrid}>
          <Grid item xs={12} sm={6} md={8}>
            <div className={`${classes.iconGrid} ${classes.agreeAndSubmitContainer}`}>
              <img src={ThankyouTick} width="86px" height="86px" alt={t('Profile picture')} />
            </div>

            <Typography className={`${classes.gracias} ${classes.centerText}`}>
              {t('Your new CD is almost complete!', {
                defaultValue: 'Your new CD is almost complete!',
              })}
            </Typography>

            <Typography
              style={{ fontSize: '20px', fontWeight: 700, marginTop: 30, marginBottom: 10 }}
            >
              {t('Read and accept your CD terms', {
                defaultValue: 'Read and accept your CD terms',
              })}
            </Typography>

            <Typography>
              {t(
                'Please read and agree the final terms of your Certificate of Deposit in order to open your account.',
                {
                  defaultValue:
                    'Please read and agree the final terms of your Certificate of Deposit in order to open your account.',
                }
              )}
            </Typography>

            <div
              style={{ marginTop: 20, marginBottom: 20 }}
              className={classes.agreeAndSubmitContainer}
            >
              <Link
                target="_blank"
                href={termsAndConditions}
                disabled={!termsAndConditions}
                id="123"
                data-testid="link_id"
                rel="noreferrer"
                className={`${classes.link} consent_links_terms`}
                aria-label="link will be opened in Link new tab"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('CD Terms & Conditions', {
                  defaultValue: 'CD Terms & Conditions',
                })}
                <img
                  src={download}
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  alt="eye icon"
                  height="24px"
                  width="24px"
                />
              </Link>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label className="checkbox_label">
                <input
                  type="checkbox"
                  onChange={(e) => setAgreeAndSubmit(e.target.checked)}
                  name="read_and_sign_check"
                  data-testid="checkbox_testid"
                  checked={agreeAndSubmit}
                  autoFocus
                />
                <span
                  style={{
                    opacity: 1,
                    marginLeft: '2px',
                    position: 'relative',
                    top: '7px',
                  }}
                  className="checkbox_custom rectangular"
                />

                <Typography
                  className={`${classes.checkbox_label_style} ${classes.checkbox_content_position}`}
                  style={{ top: '-17px', color: '#222B45' }}
                >
                  {t(
                    'I certify that I read the document above and the terms and conditions included in the same.',
                    {
                      defaultValue:
                        'I certify that I read the document above and the terms and conditions included in the same.',
                    }
                  )}
                </Typography>
              </label>
            </div>

            <div className={classes.agreeAndSubmitContainer}>
              <Button
                autoFocus
                disabled={!agreeAndSubmit}
                onClick={handleContinue}
                disableFocusRipple
                className={agreeAndSubmit ? classes.agreeAndSubmitButton : classes.disabled_button}
                data-testid="ReturnToOrientalButtonTitle"
              >
                {t('Agree & Submit', { defaultValue: 'Agree & Submit' })}
              </Button>
            </div>
          </Grid>
          {!isMobile600 && (
            <Grid item xs={12} sm={6} md={4} className={classes.cardGrid}>
              <div>
                <img src={TwoCoin} alt="layout" style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            </Grid>
          )}
        </Grid>

        // ) : (
        //   <div className="loader-style">
        //     <Loader />
        //   </div>
        // )
      )}

      {loading && (
        <div className="loader-style">
          <Loader />
        </div>
      )}
      {openAccountLoader && <CDProcessingLoader />}

      <DCDLinkExpiryModal
        expiryModal={expiryModal}
        setExpiryModal={setExpiryModal}
        modalMessage={modalMessage}
      />

      {/* <KnockOffModal
        message={{ message: modalMessageText, isKnockOff: true, hideClose: true }}
        setIsModal={setExpiryModal}
        openModal={expiryModal}
      /> */}
    </div>
  );
};

export default withRouter(EmailReviewCD);

EmailReviewCD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
